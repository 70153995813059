header {
  height: 70vh;
  padding-top: 3rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/** ===== CTA (call to action) ===== **/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/** ===== HEADER SOCIALS ===== **/
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/** ===== IMAGE OF ME ===== **/
.me {
  /*background: linear-gradient(var(--color-primary), transparent);*/

  width: 22rem;
  height: 34.5rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* combo with 'left 50%' to set it into the center */
  margin-top: 1rem;
  border-radius: 0rem;
  /*padding: 5rem 1.5rem 1.5rem 1.5rem;*/
}

.me img {
  width: 100%;
  padding: 1.5rem;
}

/** ===== SCROLL DOWN ===== **/
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/** ===== MEDIA QUERIES (Laptop Screens) ===== **/
@media screen and (max-width: 1600px) {
  header {
    height: 45rem;
  }
}

/** ===== MEDIA QUERIES (Medium Devices) ===== **/
@media screen and (max-width: 1024px) {
  header {
    height: 68hv;
  }
}

/** ===== MEDIA QUERIES (Small Devices) ===== **/
@media screen and (max-width: 600px) {
  header {
    height: 36rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .me {
    max-width: 18rem;
  }
  .me img {
    padding: 1rem 2rem 1rem 2rem;
  }

  nav {
    bottom: 20rem;
  }
}

/** ===== MEDIA QUERIES (Super Small Devices) ===== **/
@media screen and (max-width: 375px) {
  .me {
    max-width: 18rem;
  }
  .me img {
    padding: 1rem 2rem 1rem 2rem;
  }
  /* header {
    height: 36rem;
  } */
}
