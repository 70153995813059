.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  border-radius: 2.5rem;
  border: 1px solid transparent;
  transition: var(--transition);
  padding: 1.8rem 2rem;
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 1fr;
  row-gap: 1rem;
  justify-items: center;
}

.experience__details {
  display: flex;
  gap: 0.5rem;
  width: 60%;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/** ===== MEDIA QUERIES (Medium Devices) ===== **/
@media screen and (max-width: 1369px) {
  .experience__container > div {
    width: 100%;
    padding: 1.8rem 2rem;
  }
}

@media screen and (max-width: 1089px) {
  .experience__container {
    grid-template-columns: 1fr 1fr;
  }

  .experience__container > div {
    width: 100%;
    padding: 1.8rem 2rem;
  }
}

@media screen and (max-width: 1072px) {
  .experience__container > div {
    padding: 1.8rem 1rem;
  }
}

/** ===== MEDIA QUERIES (Medium Devices) ===== **/
@media screen and (max-width: 1024px) {
  .experience__container > div {
    width: 100%;
    padding: 1.8rem 5rem;
  }
}

@media screen and (max-width: 942px) {
  .experience__container > div {
    width: 100%;
    padding: 1.8rem 4rem;
  }
}

@media screen and (max-width: 864px) {
  .experience__container > div {
    padding: 1.8rem 3rem;
  }
}

@media screen and (max-width: 760px) {
  .experience__container > div {
    padding: 1.8rem 2rem;
  }
}

@media screen and (max-width: 675px) {
  .experience__container > div {
    padding: 1.8rem 1.2rem;
  }
}

@media screen and (max-width: 620px) {
  .experience__container > div {
    padding: 1.8rem 1rem;
  }
}

/** ===== MEDIA QUERIES (Small Devices) ===== **/
@media screen and (max-width: 600px) {
  .experience__container {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 1.8rem 5rem;
  }
}

@media screen and (max-width: 530px) {
  .experience__container > div {
    width: 100%;
    padding: 1.8rem 12%;
  }
}

@media screen and (max-width: 400px) {
  .experience__container > div {
    width: 100%;
    padding: 1.8rem 8%;
  }
}

/** ===== MEDIA QUERIES (Even Smaller Devices) ===== **/
@media screen and (max-width: 360px) {
  .experience__container > div {
    padding: 1.8rem 1rem;
  }
}

/** ===== MEDIA QUERIES (Even Smaller Devices) ===== **/
@media screen and (max-width: 344px) {
  .experience__container {
    width: 100%;
    padding: 0;
  }

  .experience__container > div {
    padding: 1.8rem 20%;
  }

  .experience__content {
    grid-template-columns: 1fr;
  }
}
